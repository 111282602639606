export default function getLessonsFromCacheByTheme(theme_code, theme_id) {
  let levels = JSON.parse(localStorage.getItem("levels"));
  let lessons = [];
  let themeLessons = [];
  for (const level of levels) {
    let levelId = level.level_id;
    let levelLessons = JSON.parse(localStorage.getItem("level_" + levelId));
    if (theme_code) {
      themeLessons = levelLessons.filter((obj) => {
        return obj.theme_code === theme_code;
      });
    } else if (theme_id) {
      themeLessons = levelLessons.filter((obj) => {
        return obj.theme_id === theme_id;
      });
    }
    lessons = lessons.concat(themeLessons);
  }
  return lessons;
}
